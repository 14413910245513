<template>
  <div v-if="isshowgrid">
    <va-card title="Genres List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
          <va-button flat small color="red" icon="fa fa-times" @click="removeGenres(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
    <va-modal
      v-model="showRemoveModal"
      title="Remove Genres"
      size='small'
      :message="message"
      okText="Confirm"
      cancelText="Cancel"
      @ok="deleteGenres(removed_row_content)"
      @cancel="list()">
    </va-modal>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Genres</span>
            <va-input
              v-model.trim="genres"
              type="text"              
              placeholder="Enter Genres"
              :error="!!genresErrors.length"
              :error-messages="genresErrors"
            />
            <span class="va-form-label va-form-required-label">Priority</span>
            <va-input
              v-model="order"
              type="number"              
              placeholder="Enter Priority"
              :error="!!priorityErrors.length"
              :error-messages="priorityErrors"
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createGenres()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateGenres()">Update</va-button>              
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)
export default {
  name: 'genres',
  created () {
    this.getAllGenres();
    // const loader = Vue.$loading.show({ width: 40, height: 40 })
	   //  this.$http.get(config.menu.host + '/genres').then(response => {
	   //    loader.hide()
	   //    this.genresData = response.body
	   //    this.isshowForm = false
	   //    this.isshowgrid = true
    //     this.getAllGenres()
	   //  }, error => {
	   //    loader.hide()
	   //    if (error && error.body) {
	   //      Vue.notify({ text: error.body, type: 'error' })
	   //    }
	   //  })
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      showRemoveModal: false,
      genres: '',
      order: '',
      title: '',
      sc_category : '',
      genresData: [],
      genresErrors: [],
      priorityErrors: [],
    }
  },
  computed: {
    formReady () {
      return !this.genresErrors.length && !this.priorityErrors.length
    },
    fields () {
      return [{
          name: 'sno',
          title: 'S.NO',
          width: '6%',
      },{
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'genres',
        title: 'Genres',
      },
      {
        name: 'order',
        title: 'Priority',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.genresData)
    },
  },
  methods: {
	    getAllGenres () {
	      this.$http.get(config.menu.host + '/genres').then(response => {
          let index = 0
          this.genresData = response.body.map(function (item) {
              item.id = index++
              item.sno = index
              item.is_existing = true
              item.checkbox_value = false
              return item
          })
          this.isshowForm = false
	        this.isshowgrid = true
	      })
	    },
    createGenres () {
      this.genresErrors = this.genres ? [] : ['Genres is required']
      this.priorityErrors = this.order ? [] : ['Priority is required']
      if (!this.formReady) {
        return
      }
      var payload = {
        genres: this.genres,
        order: this.order,
        sc_category : this.sc_category,
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/genres', payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updateGenres () {
      var payload = {
        genres: this.genres,
        order: this.order,
        sc_category : this.sc_category
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/genres/' + this.genres_id, payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    removeGenres (rowData) {
      this.message = 'Are you sure to delete ' + rowData.genres + ' genres ?'
      this.showRemoveModal = true
      this.removed_row_content = rowData
    },
    deleteGenres (rowData) {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.delete(config.menu.host + '/genres/' + rowData.genres_id).then(response => {
        loader.hide()
        if (response && response.body) {
          Vue.notify({ text: response.body, type: 'success' })
        }
        this.getAllGenres()
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error' })
        }
      })
    },
	  add () {
	    this.title = 'Create Genres'
      this.genres = ''
      this.order = ''
      this.sc_category = ''
      this.genresErrors = []
      this.priorityErrors = []
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
	    },
    edit (row) {
      this.title = 'Update Genres'
      this.genres_id = row.genres_id
      this.genres = row.genres
      this.order = row.order
      this.sc_category = row.sc_category
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
	    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllGenres()
      this.genresErrors =[]
      this.priorityErrors = []
    },
	    search: debounce(function (term) {
	    	this.term = term
	    }, 400),
  },
}
</script>
